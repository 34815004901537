/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /* START SELECT 2! */
        function formatState (state) {
          if (!state.id || !$(state.element).attr('data-iconurl')) {
            return state.text;
          }
          var iconUrl = $(state.element).attr('data-iconurl');
          var baseUrl = iconUrl;
          var $state = $(
            '<span><img src="' + baseUrl + '" class="img-flag" width="22px" style="margin-right: 20px;"/> ' + state.text + '</span>'
            );
          return $state;
        };
        
        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
          templateResult: formatState
        }).on('select2:open', function(e){
          setTimeout(function(){ 
            $('.select2-dropdown').addClass('opened');
          }, 200);
        }).on('select2:closing', function(e){
          // if removed, for some examples, the Select2 will not highlight the selected element
          $('.select2-dropdown').removeClass('opened');
        });
        /* END SELECT 2! */
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        jQuery(document).ready(function(){
         jQuery(window).on("scroll",function() {
          if(jQuery(this).scrollTop() > 50) 
            jQuery("header").addClass("scroll");
          else 
          jQuery("header").removeClass("scroll");
        })
       })

          /*MENU MOBILE*/
        $('.burger-container, .nav-menu-mobile a, a.button.gradient.white-bg').on('click', function (e) {
          $('header.header').toggleClass('menu-opened');
          //$('#menu-full').fadeToggle(200);
          $('#menu-full').toggleClass('opened');
          $('.menu-bg').toggleClass('opened');
          $('body').toggleClass('over');
          $('html').toggleClass('over');
        });

        $('a.gradient.top').on('click', function (e) {
          $('header.header').removeClass('menu-opened');
          //$('#menu-full').fadeToggle(200);
          $('#menu-full').removeClass('opened');
          $('.menu-bg').removeClass('opened');
          $('body').removeClass('over');
          $('html').removeClass('over');
        });

        $('#menu-full .menu-item-has-children').on('click', function (e) {
          $(this).toggleClass('open');
          $(this).find('.sub-menu').slideToggle();
        });

        $('#menu-full div.menu ul li a.directors').on('click', function (e) {
          $('header.header').toggleClass('menu-opened');
          //$('#menu-full').fadeToggle(200);
          $('#menu-full').toggleClass('opened');
          $('.menu-bg').toggleClass('opened');
          $('body').toggleClass('over');
          $('html').toggleClass('over');
        });
        
        var bannerhome = new Swiper(".banner-home", {
         watchOverflow: true,
         pagination: {
          el: ".slider .swiper-pagination",
        },
        autoplay: {
          delay: 5000,
        },
      });

        var parceiros = new Swiper(".slider-parceiros", {
          spaceBetween: 8,
          pagination: {
            el: ".parceiros .swiper-pagination",
          },
          autoplay: {
            delay: 4000,
          },
          breakpoints: {
            320: {
              slidesPerView: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
          },
        });


  // init controller
        var controller = new ScrollMagic.Controller({globalSceneOptions: {duration: "100%"}});

  // build scenes
        new ScrollMagic.Scene({triggerElement: "#quem-somos"})
          .setClassToggle(".menu-item-1920 a", "active") // add class toggle
          //.addIndicators() // add indicators (requires plugin)
          .addTo(controller);

          new ScrollMagic.Scene({triggerElement: "#parceiros"})
          .setClassToggle(".menu-item-1921 a", "active") // add class toggle
          //.addIndicators() // add indicators (requires plugin)
          .addTo(controller);

          new ScrollMagic.Scene({triggerElement: "#simulador"})
          .setClassToggle(".menu-item-1985 a", "active") // add class toggle
          //.addIndicators() // add indicators (requires plugin)
          .addTo(controller);

          new ScrollMagic.Scene({triggerElement: "#contactos"})
          .setClassToggle(".menu-item-1925 a", "active") // add class toggle
          //.addIndicators() // add indicators (requires plugin)
          .addTo(controller);

        }

      },
    // About us page, note the change from about-us to about_us.
      'about_us': {
        init: function() {
        // JavaScript to be fired on the about us page
        }
      }
    };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';

        if (fire) {
          namespace[func][funcname](args);
        }
      },
      loadEvents: function() {
      // Fire common init JS
        UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        });

      // Fire common finalize JS
        UTIL.fire('common', 'finalize');
      }
    };

  // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
